import usePageDataLayer from './use-page-data-layer'

const handlePageMessage = ({ selector, context, trigger }, pushMessage) => {
  if (/^TopBanner\/Carousel.banner#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: context.banner.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_top_banner',
            item_list_name: 'top_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: context.banner.name,
            promotion_id: context.banner.name,
            creative_name: context.banner.name,
            creative_slot: 'top_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }

  if (/^ShortcutMenu\/\[MenuItem\]#\d+$/i.test(selector)) {
    pushMessage({
      event: 'shortcut_menu',
      page_group: 'tmh_postpaid-move_to_true',
      shortcut_menu: context.item.name,
    })
  }

  if (/^ServiceBanner\/Grid.banner#\d+$/i.test(selector)) {
    pushMessage({
      event: 'product_and_service_card',
      page_group: 'tmh_postpaid-move_to_true',
      card_name: context.banner.name,
    })
  }

  if (/^MobilePackage\/Tabs.tab#.+$/i.test(selector)) {
    pushMessage({
      event: 'package_type_menu',
      page_group: 'tmh_postpaid-move_to_true',
      package_type: context.tab.title,
    })
  }

  if (/^MobilePackage\/\[PostpaidCard\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.priceplan.normalPrice) -
      parseFloat(context.pack.priceplan.sellingPrice)

    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.mobilePackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'postpaid_package',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_postpaid_package',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: parseFloat(context.pack.priceplan.sellingPrice),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PostpaidCard.button#1\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.priceplan.normalPrice) -
      parseFloat(context.pack.priceplan.sellingPrice)

    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid-move_to_true',
      button: context.pack.priceplan.buttonName1,
      item_id: context.pack.id,
      item_name: context.pack.name,
      coupon_name: 'none',
      discount: isNaN(discount) ? 'none' : discount,
      index: context.mobilePackages[0].packages.findIndex(
        pack => pack.id === context.pack.id
      ),
      item_brand: 'none',
      item_category: 'postpaid_package',
      item_list_id: 'home_postpaid_package',
      item_list_name: context.header.name,
      item_variant: 'none',
      price: parseFloat(context.pack.priceplan.sellingPrice),
    })
  }

  if (/^MobilePackage\/\[PostpaidCard.button#2\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.priceplan.normalPrice) -
      parseFloat(context.pack.priceplan.sellingPrice)

    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.mobilePackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'postpaid_package',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_postpaid_package',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: parseFloat(context.pack.priceplan.sellingPrice),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PrepaidCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobilePackages[1].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'prepaid_package',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_prepaid_package',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: parseFloat(context.pack.priceplan.price),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PrepaidCard.button#1\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid-move_to_true',
      button: context.pack.priceplan.buttonName1,
      item_id: context.pack.id,
      item_name: context.pack.name,
      coupon_name: 'none',
      discount: 'none',
      index: context.mobilePackages[1].packages.findIndex(
        pack => pack.id === context.pack.id
      ),
      item_brand: 'none',
      item_category: 'none',
      item_list_id: 'home_prepaid_package',
      item_list_name: context.header.name,
      item_variant: 'none',
      price: parseFloat(context.pack.priceplan.price),
    })
  }

  if (/^MobilePackage\/\[PrepaidCard.button#2\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobilePackages[1].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_prepaid_package',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: parseFloat(context.pack.priceplan.price),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[ExtraCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id.split(':').slice(1).join(':'),
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobilePackages[2].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category:
              (context.pack.priceplan.gtmL1Department ??
                '1_Telecomunication (Telco)') +
              '|' +
              (context.pack.priceplan.gtmL2Category ?? 'Mobile & Gadgets'),
            item_category2:
              (context.pack.priceplan.gtmL3SubCategory ?? 'SIM') +
              '|' +
              (context.pack.priceplan.gtmL4Class ?? 'Post-Paid'),
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant:
              context.pack.priceplan.data +
              '|' +
              context.pack.priceplan.priceplanType,
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PostpaidExtendedCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.priceplan.id,
            item_name:
              context.pack.name + ' ' + context.pack.priceplan.sellingPrice,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.pack.position,
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PrepaidExtendedCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.priceplan.id,
            item_name:
              context.pack.name + ' ' + context.pack.priceplan.sellingPrice ??
              '',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.pack.position - 1,
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Pre-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[ExtraCard.button\]#.+$/i.test(selector)) {
    let allItems = []
    context.mobilePackages[2].packages.map((pack, index) => {
      if (pack.id === context.pack.id) {
        allItems.push({
          item_id: pack.id.split(':').slice(1).join(':'),
          item_name: pack.name,
          coupon: 'none',
          currency: 'THB',
          discount: 'none',
          index: index,
          item_brand: 'none',
          item_category:
            (pack.priceplan.gtmL1Department ?? 'none') +
            '|' +
            (pack.priceplan.gtmL2Category ?? 'none'),
          item_category2:
            (pack.priceplan.gtmL3SubCategory ?? 'none') +
            '|' +
            (pack.priceplan.gtmL4Class ?? 'none'),
          item_category3: 'none|none',
          item_category4: 'none|none',
          item_category5: 'none|none',
          item_list_id: 'home_extra_package',
          item_list_name: context.header.name,
          item_variant:
            pack.priceplan.data + '|' + pack.priceplan.priceplanType,
          price: 'none',
          quantity: 1,
        })
      }
    })
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: allItems,
      },
    })
  }

  if (/^MobilePackage\/\[PostpaidExtendedCard.button\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.priceplan.id,
            item_name:
              context.pack.name + ' ' + context.pack.priceplan.sellingPrice ??
              '',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.pack.position - 1,
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Extra package',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PrepaidExtendedCard.button\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.priceplan.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.pack.position - 1,
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Pre-paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/MobilePackage.viewAllButton#.+$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'view_all',
      section_name: {
        postpaid: 'postpaid_package',
        prepaid: 'prepaid_package',
        extra: 'extra_package',
      }[context.tab.type],
    })
  }

  if (/^HighlightBanner\/Grid.banner#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_item', view: 'view_item_list' }[trigger],
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_top_banner',
            item_list_name: 'highlight_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'highlight_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/\[Card\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.deviceManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'smartphone_and_tablet',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_smartphone_and_tablet',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/\[Card.button\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.deviceManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'smartphone_and_tablet',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_smartphone_and_tablet',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/DeviceShelf.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'view_all',
      section_name: context.header.name,
    })
  }

  if (/^IotShelf\/\[Card\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.iotManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'accessories',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_accessories',
            item_list_name: 'accessories',
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^IotShelf\/\[Card.button\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.iotManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'accessories',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_accessories',
            item_list_name: 'accessories',
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^IotShelf\/DeviceShelf.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'view_all',
      section_name: 'accessories',
    })
  }

  if (/^InternetPackage\/\[Card\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.price) - parseFloat(context.pack.priceSpecial)

    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.internetPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'internet_fiber',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_internet_fiber',
            item_list_name: 'internet_fiber',
            item_variant: 'none',
            price: parseFloat(context.pack.priceSpecial),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^InternetPackage\/\[Card.button#1\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.price) - parseFloat(context.pack.priceSpecial)

    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid-move_to_true',
      button: context.pack.buttonName1,
      item_id: context.pack.id,
      item_name: context.pack.name,
      coupon_name: 'none',
      discount: isNaN(discount) ? 'none' : discount,
      index: context.internetPackages[0].packages.findIndex(
        pack => pack.id === context.pack.id
      ),
      item_brand: 'none',
      item_category: 'internet_fiber',
      item_list_id: 'home_internet_fiber',
      item_list_name: 'internet_fiber',
      item_variant: 'none',
      price: parseFloat(context.pack.priceSpecial),
    })
  }

  if (/^InternetPackage\/\[Card.button#2\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.price) - parseFloat(context.pack.priceSpecial)

    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.internetPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: 'internet_fiber',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_internet_fiber',
            item_list_name: 'internet_fiber',
            item_variant: 'none',
            price: parseFloat(context.pack.priceSpecial),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (selector === 'InternetPackage/ContactModal.termsButton') {
    let discount =
      parseFloat(context.selectedPack.price) -
      parseFloat(context.selectedPack.priceSpecial)

    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'terms_and_conditions',
      item_id: context.selectedPack.id,
      item_name: context.selectedPack.name,
      coupon_name: 'none',
      discount: isNaN(discount) ? 'none' : discount,
      index: context.internetPackages[0].packages.findIndex(
        pack => pack.id === context.selectedPack.id
      ),
      item_brand: 'none',
      item_category: 'internet_fiber',
      item_list_id: 'home_internet_fiber',
      item_list_name: 'internet_fiber',
      item_variant: 'none',
      price: parseFloat(context.selectedPack.priceSpecial),
    })
  }

  if (selector === 'InternetPackage/ContactModal.submitButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'submit',
      section_name: 'internet_fiber',
    })
  }

  if (selector === 'InternetPackage/ContactModal.retryButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'retry',
      section_name: 'internet_fiber',
    })
  }

  if (selector === 'InternetPackage/ContactModal.doneButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'back',
      section_name: 'internet_fiber',
    })
  }

  if (selector === 'InternetPackage/ContactModal.alreadyInQueueErrorForm') {
    pushMessage({
      event: 'result_pop_up',
      page_group: 'tmh_postpaid-move_to_true',
      pop_up_result: 'already_in_queue_error',
      section_name: 'internet_fiber',
      circuit_no: 0,
      package: context.selectedPack.name,
    })
  }

  if (selector === 'InternetPackage/ContactModal.unknownErrorForm') {
    pushMessage({
      event: 'result_pop_up',
      page_group: 'tmh_postpaid-move_to_true',
      pop_up_result: 'unknown_error',
      section_name: 'internet_fiber',
      circuit_no: 0,
      package: context.selectedPack.name,
    })
  }

  if (selector === 'InternetPackage/ConsentModal.acceptButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'accept',
      section_name: 'internet_fiber',
    })
  }

  if (selector === 'InternetPackage/ConsentModal.skipButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'skip',
      section_name: 'internet_fiber',
    })
  }

  if (selector === 'InternetPackage/ConsentModal.successForm') {
    pushMessage({
      event: 'result_pop_up',
      page_group: 'tmh_postpaid-move_to_true',
      pop_up_result: 'completed',
      section_name: 'internet_fiber',
      circuit_no: 0,
      package: context.selectedPack.name,
    })
  }

  if (selector === 'InternetPackage/ConsentModal.doneButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'done',
      section_name: 'internet_fiber',
    })
  }

  if (/^InternetPackage\/InternetPackage.viewAllButton#.+$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'view_all',
      section_name: 'internet_fiber',
    })
  }

  if (
    /^TMHMoveToTruePrivilegeShelf\/PrivilegeShelf.Card#.+$/i.test(selector) &&
    trigger === 'click'
  ) {
    pushMessage({
      event: 'select_promotion',
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.id.toString(),
            item_name: context.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.privileges.findIndex(pack => pack.id === context.id),
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'benefits',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: context.name,
            promotion_id: context.name,
            creative_name: context.name,
            creative_slot: 'benefits',
            location_id: context.privileges.findIndex(
              pack => pack.id === context.id
            ),
          },
        ],
      },
    })
  }

  if (
    /^TMHMoveToTruePrivilegeShelf\/\[Card.button\]#.+$/i.test(selector) &&
    trigger === 'click'
  ) {
    pushMessage({
      event: 'view_content',
      page_group: 'tmh_postpaid-move_to_true',
      content_name: context.privilege.name,
      section_name: context.pageHeaders.mnpPage.topHeader.name,
      content_type: 'สิทธิพิเศษเหนือใคร',
    })
  }

  if (/^TMHMoveToTrueContactChannel\/Grid.button#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: 'tmh_postpaid-move_to_true',
      content_name: context.channel.name,
      section_name: context.header.name,
      content_type: 'contact_channel',
    })
  }

  if (/^Faq\/\[Card\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'คลิกที่นี่',
      section_name: context.header.name,
    })
  }

  if (selector === 'Faq/FaqButton') {
    pushMessage({
      event: 'select_content',
      page_group: 'tmh_postpaid-move_to_true',
      content_name: 'See more',
      section_name: context.header.name,
      content_type: 'faq',
    })
  }

  if (/^DeviceShelf\/DeviceShelf.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid-move_to_true',
      button: 'view_all',
      section_name: context.header.name,
    })
  }

  if (selector === 'BottomBanner/Banner') {
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: 'tmh_postpaid-move_to_true',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'home_bottom_banner',
            item_list_name: 'bottom_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'bottom_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }
}

const useTMHMoveToTrueDataLayer = () => {
  let { createHandler, createScopeProps } = usePageDataLayer()
  let handleMessage = createHandler(handlePageMessage)
  return { handleMessage, createScopeProps }
}

export default useTMHMoveToTrueDataLayer
