import { css } from '@emotion/react'

const style = {
  wrapper: centered => css`
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;

    ${centered &&
    `
      justify-content: center;
    `}

    @media (min-width: 768px) {
      margin: -0.5rem;
    }
  `,

  box: css`
    padding: 0.25rem;
    white-space: pre-wrap;

    @media (min-width: 768px) {
      padding: 0.5rem;
    }
  `,

  card: css`
    width: auto;
  `,
}

export default style
