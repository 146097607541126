import { useState } from 'react'
import { Grid, Modal } from 'swp-components/lib/components/contact-channel'

const ContactChannel = ({ channels, ...props }) => {
  let [selectedChannel, setSelectedChannel] = useState(null)
  let [modalShow, setModalShow] = useState(false)

  let handleAction = (action, payload) => {
    if (action === 'open-chat') {
      window.open(
        process.env.GATSBY_MNP_LIVE_CHAT,
        'mnp-live-chat',
        'popup, width=400, height=600'
      )
    }

    if (action === 'show-detail') {
      setSelectedChannel(payload.channel)
      setModalShow(true)
    }

    if (action === 'close-modal') {
      setModalShow(false)
    }
  }

  return (
    <div {...props}>
      <Grid channels={channels} onAction={handleAction} />
      <Modal
        channel={selectedChannel}
        show={modalShow}
        onAction={handleAction}
      />
    </div>
  )
}

export default ContactChannel
