import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/faq'

import useColumns from '@/hooks/use-columns'

import style from './style'

const FaqGrid = ({ color, topics, ...props }) => {
  let [rows, setRows] = useState(1)

  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 2 },
    { value: breakpoints.md, columns: 3 },
    { value: breakpoints.lg, columns: 4 },
    { value: breakpoints.xxl, columns: 5 },
  ])

  let centered = topics.length < columns

  let visibleTopics = topics.slice(0, columns * rows)

  return (
    <Container {...props}>
      <div css={style.wrapper(centered)}>
        {visibleTopics.map(topic => (
          <div
            key={topic.id}
            css={style.box}
            style={{ width: `${100 / columns}%` }}
          >
            <Card css={style.card} color={color} topic={topic} />
          </div>
        ))}
      </div>
      {visibleTopics.length < topics.length && (
        <div className="d-flex justify-content-center mt-3">
          <DataLayer
            selector={`FaqButton`}
            triggers={['click']}
            context={visibleTopics}
            render={ref => (
              <Button
                ref={ref}
                color="white"
                variant="outlined"
                onClick={() => setRows(rows + 3)}
              >
                <FormattedMessage id="viewMore" defaultMessage="View More" />
              </Button>
            )}
          />
        </div>
      )}
      {visibleTopics.length >= topics.length && (
        <div className="d-flex justify-content-center mt-3">
          <DataLayer
            selector={`FaqButton`}
            triggers={['click']}
            context={visibleTopics}
            render={ref => (
              <Button
                ref={ref}
                color="white"
                variant="outlined"
                onClick={() => setRows(1)}
              >
                <FormattedMessage id="viewLess" defaultMessage="View Less" />
              </Button>
            )}
          />
        </div>
      )}
    </Container>
  )
}

export default FaqGrid
